;globalThis["_sentryRewritesTunnelPath"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"zJE-SBg4YGpqbcYfVBKHD"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: "https://66d8d42cdc048ce3311b84527e8c7f9f@sentry.ohotaktiv.tech/8",

  integrations: [
    Sentry.browserProfilingIntegration(),
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  initialScope: {
    tags: {
      "startup.type": "deferrer",
    },
  },
  tracePropagationTargets: ["localhost"],
  environment: process.env.NODE_ENV,
  //TODO: umenshil znachenie, kak budto page gruzitsya dolshe
  tracesSampleRate: 0.5,
  replaysSessionSampleRate: 0.3,
  replaysOnErrorSampleRate: 1.0,
  debug: false,
});
